
export const serverAppConfig = {
  "storeType": "B2C",
  "storeDeliveries": [
    {
      "id": "644b8642252ffe45ced476b4",
      "name": "Delivery 1",
      "label": "1 - 3 dagar",
      "buyable": true
    }
  ],
  "language": "sv",
  "paths": {
    "brandsUrlPath": "/varumarken",
    "categoriesUrlPath": "/categories",
    "tagsUrlPath": "/t",
    "productsUrlPath": "/p",
    "blogsUrlPath": "/blog"
  },
  "staticLinks": [
    {
      "id": "blog",
      "label": "Blogg",
      "path": "/blog"
    }
  ],
  "logoUrl": "https://storage.googleapis.com/gecko-media/EC_1033/store/vigneron-black.png",
  "showCart": true,
  "sortingOptions": [
    {
      "sortBy": "price",
      "sortOrder": "DESC",
      "sortLabel": "Pris högt-lågt",
      "default": false
    },
    {
      "sortBy": "price",
      "sortOrder": "ASC",
      "sortLabel": "Pris lågt-högt",
      "default": true
    },
    {
      "sortBy": "created",
      "sortOrder": "DESC",
      "sortLabel": "Nyaste",
      "default": false
    }
  ],
  "desktopItemHeight": "20vw",
  "mobileItemHeight": "75vw",
  "sliderDefaultValues": [
    0,
    200000
  ],
  "freeShippingOver": 499,
  "shippingProviderConfig": {
    "provider": "INGRID",
    "simpleShipping": {
      "minimumOrderAmountForFreeDelivery": 499,
      "deliveryFee": 139
    },
    "ingridOptions": null
  },
  "paymentProviderConfig": {
    "providerName": "KLARNA",
    "providerOptions": {
      "klarna": {
        "purchaseCountry": "SE",
        "purchaseCurrency": "SEK",
        "phoneMandatory": true,
        "colorButton": null
      }
    }
  },
  "filtersBeforeShowMore": 3,
  "sliderMaxPrice": 200000,
  "topNavHeight": {
    "panel": {
      "base": 0,
      "sm": "5vh"
    },
    "content": {
      "base": "8vh",
      "sm": "14vh"
    },
    "spacer": {
      "base": "8vh",
      "sm": "19vh"
    },
    "hero": {
      "base": "92vh",
      "sm": "81vh",
      "md": "60vh"
    }
  },
  "topNavSlideOutFeaturedCategories": 3,
  "socialMediaItems": [
    {
      "label": "Facebook",
      "icon": "FACEBOOK",
      "href": "https://www.facebook.com/Vigneron.se/"
    },
    {
      "label": "Instagram",
      "icon": "INSTAGRAM",
      "href": "https://www.instagram.com/vigneron.se/?hl=sv"
    }
  ],
  "topNavPanel": {
    "items": [
      {
        "icon": "FaCrown",
        "label": "Om oss",
        "href": "/om-vigneron"
      },
      {
        "icon": "GrCatalog",
        "label": "Katalog",
        "href": "https://storage.googleapis.com/gecko-media/EC_1033/landingpage/Vigneron%20Katalog%202024%20Komprimerad.pdf"
      },
      {
        "icon": "CiGlobe",
        "label": "Våra återförsäljare",
        "href": "/aterforsaljare"
      }
    ]
  }
};
export const serverTheme = {}; 
